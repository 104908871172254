import React, { useEffect, useState, useRef, useCallback } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import moment from 'moment'
import '../../styles/CashierStyle.css'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const SaleRecords = () => {
  const [auth] = useAuth()
  const [records, setRecords] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const observer = useRef()
  const navigate = useNavigate()

  const getSaleRecords = async (currentPage) => {
    try {
      setLoading(true)
      const userId = auth?.user?._id
      const { data } = await axios.get(`/api/v1/order/sale-records`, {
        params: { page: currentPage, userId },
      })
      setLoading(false)
      if (data.records.length > 0) {
        setRecords((prev) => [...prev, ...data.records])
      } else {
        setHasMore(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getSaleRecords(page)
  }, [page])

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const handleReturnHome = () => {
    navigate('/cashier/dashboard')
  }

  return (
    <Layout title={'Transaction Records'}>
      <div className='col-md-12'>
        <div className='col-md-12'>
          <div
            className='searchInventory shadow-sm p-3 bg-white rounded'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '60px',
              position: 'fixed',
              zIndex: 1000,
            }}
          >
            <h5 style={{ margin: 0, color: '#49759e' }}>Transaction Records</h5>
            <div>
              <button
                type='button'
                className='btn btn-outline-info btn-sm'
                onClick={() => window.scrollTo(0, 0)}
              >
                <i className='bi bi-arrow-up-circle'></i>
              </button>

              <button
                type='button'
                className='btn btn-outline-secondary btn-sm ms-3'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className='table-responsive'>
            <table className='table actable' style={{ marginTop: '70px' }}>
              <thead>
                <tr>
                  <th scope='col'>Date</th>
                  <th scope='col'>Order ID</th>
                  <th scope='col' className='text-center'>
                    Item Qty
                  </th>
                  <th scope='col' className='text-end'>
                    Total (RM)
                  </th>
                  <th scope='col'>Payment</th>
                  <th scope='col'>Reference No.</th>
                </tr>
              </thead>

              <tbody>
                {records.map((record, recordIndex) => (
                  <React.Fragment key={record._id}>
                    <tr className='sale-records'>
                      <td>
                        {moment(record.orderdate)
                          .subtract(8, 'hours')
                          .format('YYYY-MM-DD HH:mm')}
                      </td>
                      <td>{record.orderid}</td>
                      <td className='text-center'>{record.products.length}</td>
                      <td className='text-end'>
                        {(Math.round(record.totalorders * 100) / 100).toFixed(
                          2
                        )}
                      </td>
                      <td>{record.paymentmethod}</td>
                      <td>{record.paymentrefno}</td>
                    </tr>
                    <tr key={`product-details-${record._id}`}>
                      <td colSpan={7}>
                        {record.products.map((product, productIndex) => (
                          <div
                            key={`product-${record._id}-${productIndex}`} // Ensure unique key for each product
                            className='mb-1'
                            style={{ fontSize: '17px' }}
                          >
                            {productIndex + 1}.{' '}
                            <span className='ms-1 me-2'>{product.refcode}</span>{' '}
                            {product.name}{' '}
                            <span className='ms-2'>x {product.quantity}</span>
                            <span className='ms-3'>
                              RM{' '}
                              {(
                                Math.round(
                                  product.quantity * product.price * 100
                                ) / 100
                              ).toFixed(2)}
                            </span>
                          </div>
                        ))}
                      </td>
                    </tr>
                    {recordIndex === records.length - 1 && (
                      <tr ref={lastElementRef} key={`last-row-${record._id}`}>
                        <td colSpan={7} style={{ visibility: 'hidden' }}></td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {loading && <div>Loading...</div>}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SaleRecords
