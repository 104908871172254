import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./../components/Layout/Layout";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Layout title={"Home Page"}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 250 }}
      >
        <div className="p-2 m-2 rounded-2 text-center">
          <div className="h2 mt-5" style={{ color: "#2f4483" }}>
            POS System
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
