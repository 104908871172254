import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <Layout title={'Admin Dashboard'}>
      <div className='container'>
        <div className='row container'>
          <div className='col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/report'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Sales Report
              </Link>
            </div>
          </div>

          <div className='col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/products'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Products
              </Link>
            </div>
          </div>

          <div className='col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/users'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Users
              </Link>
            </div>
          </div>

          {/* <div className='col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/category'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Categories
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default AdminDashboard
