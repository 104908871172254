import React from 'react'
import Header from './Header'
import AdminHeader from './AdminHeader'
import LoginHeader from './LoginHeader'
import { useAuth } from '../../context/auth'
import { Helmet } from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Layout = ({ children, title, description, keywords, author }) => {
  const [auth] = useAuth()

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='author' content={author} />
        <title>{title}</title>
      </Helmet>
      {auth?.user !== null || auth?.user !== undefined ? (
        auth?.user?.role === 1 ? (
          <AdminHeader />
        ) : (
          <Header />
        )
      ) : (
        <LoginHeader />
      )}

      <main style={{ marginTop: '50px' }}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme='light'
        />
        {children}
      </main>
    </div>
  )
}

export default Layout
