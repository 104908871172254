import React, { useEffect, useState, useRef, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import ProductSaleDetails from './ProductSaleDetails'

const AdminProductList = () => {
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [hasMore, setHasMore] = useState(true)

  const [visibleModal, setVisibleModal] = useState(false)
  const [productName, setProductName] = useState('')
  const [saleRecords, setSaleRecords] = useState()
  const [editingProduct, setEditingProduct] = useState(null)

  const inputRef = useRef(null)
  const observer = useRef()
  const navigate = useNavigate()

  // Get All Products
  const getAllProducts = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`/api/v1/product/all-product/${page}`)
      setLoading(false)
      setProducts((prevProducts) => [...prevProducts, ...data.products])
      setHasMore(data.products.length > 0)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  // Search Product
  const searchProductResult = async () => {
    try {
      const { data } = await axios.post('/api/v1/product/search-product', {
        search,
      })
      setProducts(data?.products)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (search.length > 3) searchProductResult()
  }, [search])

  const clearInput = () => {
    setSearch('')
    setPage(1)
    setProducts([])
  }

  useEffect(() => {
    if (!search) {
      getAllProducts()
    }
  }, [page, search])

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleModal(false)
  }

  const getProductSaleRecord = async (productId, productName) => {
    try {
      const { data } = await axios.post(
        '/api/v1/product/product-sale-details',
        { productId }
      )
      setSaleRecords(data?.productSale)
      setProductName(productName)
      setVisibleModal(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDoubleClick = (productId, field, value) => {
    setEditingProduct({ productId, field, value })
  }

  const handleInputChange = (e) => {
    setEditingProduct((prev) => ({
      ...prev,
      value: e.target.value,
    }))
  }

  const handleSave = async () => {
    try {
      const { productId, field, value } = editingProduct

      const originalProduct = products.find(
        (product) => product._id === productId
      )
      if (originalProduct && originalProduct[field] === value) {
        setEditingProduct(null)
        return
      }

      await axios.put(`/api/v1/product/update-product/${productId}`, {
        field,
        value,
      })

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === productId ? { ...product, [field]: value } : product
        )
      )

      setEditingProduct(null)
    } catch (error) {
      console.error('Error updating product:', error)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  useEffect(() => {
    if (editingProduct && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [editingProduct?.productId, editingProduct?.field])

  // Intersection Observer
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Products'}>
      <div
        className='searchInventory shadow-sm p-2 bg-white rounded'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h5 style={{ margin: 0, color: '#49759e' }}>Products</h5>
          <div className='col-auto'>
            <button
              type='button'
              className='btn btn-outline-primary btn-sm'
              onClick={() => navigate('/admin/product/new')}
            >
              <i className='bi bi-plus-circle'></i> Product
            </button>

            <button
              type='button'
              className='btn btn-outline-secondary btn-sm ms-3'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <div
          className='d-flex justify-content-between align-items-center flex-wrap'
          style={{ marginTop: '10px', marginBottom: '5px' }}
        >
          <div className='col-auto flex-grow-1' style={{ width: 'auto' }}>
            <input
              type='text'
              className='form-control py-12'
              value={search}
              placeholder='Search Item Code / Book / IBSN'
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className='col-auto'>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={clearInput}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '100px' }}>
            <thead>
              <tr>
                <th scope='col'>Item Code</th>
                <th scope='col'>Book</th>
                <th className='text-end' scope='col'>
                  Qty
                </th>
                <th className='text-end text-nowrap' scope='col'>
                  Total Sale
                </th>
                <th className='text-end text-nowrap' scope='col'>
                  Price (RM)
                </th>
                <th scope='col'>IBSN</th>
              </tr>
            </thead>

            <tbody>
              {products.map((product, index) => (
                <tr
                  key={product._id}
                  ref={products.length === index + 1 ? lastElementRef : null}
                >
                  <td className='text-nowrap'>{product.refcode}</td>
                  <td>{product.name}</td>
                  <td
                    className='text-end'
                    onDoubleClick={() =>
                      handleDoubleClick(
                        product._id,
                        'quantity',
                        product.quantity
                      )
                    }
                  >
                    {editingProduct?.productId === product._id &&
                    editingProduct?.field === 'quantity' ? (
                      <input
                        ref={inputRef}
                        type='number'
                        value={editingProduct.value}
                        onChange={handleInputChange}
                        onBlur={handleSave}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      product.quantity
                    )}
                  </td>
                  <td className='text-end'>
                    <span
                      onClick={() =>
                        getProductSaleRecord(product._id, product.name)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {product.saleQty}
                    </span>
                  </td>
                  <td
                    className='text-end'
                    onDoubleClick={() =>
                      handleDoubleClick(product._id, 'price', product.price)
                    }
                  >
                    {editingProduct?.productId === product._id &&
                    editingProduct?.field === 'price' ? (
                      <input
                        ref={inputRef}
                        type='number'
                        value={editingProduct.value}
                        onChange={handleInputChange}
                        onBlur={handleSave}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      (Math.round(product.price * 100) / 100).toFixed(2)
                    )}
                  </td>
                  <td
                    onDoubleClick={() =>
                      handleDoubleClick(product._id, 'ibsn', product.ibsn)
                    }
                  >
                    {editingProduct?.productId === product._id &&
                    editingProduct?.field === 'ibsn' ? (
                      <input
                        ref={inputRef}
                        type='text'
                        value={editingProduct.value}
                        onChange={handleInputChange}
                        onBlur={handleSave}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      product.ibsn
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        onCancel={() => setVisibleModal(false)}
        footer={null}
        open={visibleModal}
        width={1000}
      >
        <ProductSaleDetails
          productName={productName}
          saleRecords={saleRecords != null ? saleRecords : ''}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  )
}

export default AdminProductList
