import { Routes, Route } from 'react-router-dom'
import AdminDashboard from './pages/Admin/Dashboard'
import Login from './pages/Auth/Login'
import CashierDashboard from './pages/Cashier/Dashboard'
import AdminUsers from './pages/Admin/Users/UsersList'
import AdminCreateUser from './pages/Admin/Users/CreateUser'
import AdminCategoryList from './pages/Admin/Category/CategoryList'
import AdminSubcategoryList from './pages/Admin/Subcategory/SubcategoryList'
import AdminProductList from './pages/Admin/Product/ProductList'
import AdminCreateProduct from './pages/Admin/Product/CreateProduct'
import HomePage from './pages/HomePage'
import SaleRecords from './pages/Cashier/SaleRecords'
import PageNotFound from './pages/PageNotFound'
import UserRoute from './components/Routes/UserRoute'
import AdminRoute from './components/Routes/AdminRoute'
import CloseCounter from './pages/Cashier/CloseCounter'
import AdminEditUser from './pages/Admin/Users/EditUser'
import AdminReportList from './pages/Admin/Report/ReportList'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />

        <Route path='/cashier' element={<UserRoute />}>
          <Route path='dashboard' element={<CashierDashboard />} />
          <Route path='transaction-records' element={<SaleRecords />} />
          <Route path='close-counter' element={<CloseCounter />} />
        </Route>

        <Route path='/admin' element={<AdminRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='users' element={<AdminUsers />} />
          <Route path='users/new' element={<AdminCreateUser />} />
          <Route path='users/:id' element={<AdminEditUser />} />
          <Route path='category' element={<AdminCategoryList />} />
          <Route path='subcategory' element={<AdminSubcategoryList />} />
          <Route path='products' element={<AdminProductList />} />
          <Route path='product/new' element={<AdminCreateProduct />} />
          <Route path='report' element={<AdminReportList />} />
        </Route>

        <Route path='/login' element={<Login />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
