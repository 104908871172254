import React from 'react'

const SearchProductForm = ({
  value,
  setValue,
  handleSubmit,
  products,
  selectProduct,
}) => {
  return (
    <div>
      <div className='input-group mt-4 mb-2'>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <input
            type='text'
            className='form-control'
            placeholder='Enter Book Name'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </form>
      </div>
      <div className='w-100'>
        <table className='table'>
          <thead className='searchContain'>
            <tr>
              <th scope='col'>Item Code</th>
              <th style={{ width: '50%' }}>Description</th>
              <th className='text-end'>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='searchContain pb-5'>
            {products.map((product) => (
              <>
                <tr>
                  <td>{product.refcode}</td>
                  <td style={{ width: '50%' }}>{product.name}</td>
                  <td className='text-end'>
                    {(Math.round(product.price * 100) / 100).toFixed(2)}
                  </td>
                  <td className='text-end'>
                    <button
                      type='button'
                      class='btn btn-outline-success btn-sm'
                      onClick={(e) => selectProduct(e, product)}
                    >
                      Select
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SearchProductForm
