import React from 'react'
import { useAuth } from '../../context/auth'
import { NavLink, Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AdminHeader = () => {
  const [auth, setAuth] = useAuth()

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: '',
    })
    toast.success('Logout Successfully')
    localStorage.removeItem('auth')
  }

  return (
    <>
      <nav
        className='navbar navbar-expand-lg fixed-top'
        style={{ backgroundColor: '#49759e', height: '50px' }}
      >
        <div className='container-fluid'>
          {auth?.user ? (
            <span className='navbar-brand' style={{ color: 'white' }}>
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                to='/admin/dashboard'
              >
                <i className='bi bi-house-fill'></i>
              </Link>
            </span>
          ) : (
            ''
          )}
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item pt-1'>
                {auth?.user ? (
                  <span
                    className='navbar-brand fs-5'
                    style={{ color: 'white' }}
                  >
                    {auth?.user?.name}
                  </span>
                ) : (
                  ''
                )}
              </li>
              <li className='nav-item'>
                {auth?.user ? (
                  <NavLink
                    onClick={handleLogout}
                    to='/login'
                    className='nav-link'
                    style={{ color: 'white' }}
                  >
                    <i className='bi bi-box-arrow-right'></i>
                  </NavLink>
                ) : (
                  <Link
                    to='/login'
                    className='nav-link'
                    style={{ color: 'white' }}
                  >
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default AdminHeader
