import React from 'react'

const RefCodePaymentForm = ({
  title,
  handleSubmit,
  value,
  refCodeValue,
  setRefCodeValue,
  closeCashModal,
}) => {
  return (
    <>
      <div className='mt-1 text-center h5 shadow-sm p-2 bg-white rounded'>
        {title}
      </div>
      <form className='mt-3 h5'>
        <div className='form-group row'>
          <label htmlFor='inputPassword' className='col-sm-6 col-form-label'>
            Final Total
          </label>
          <div className='col-sm-6'>
            <input
              type='text'
              readOnly
              className='form-control-plaintext'
              value={'RM ' + (Math.round(value * 100) / 100).toFixed(2)}
            />
          </div>
        </div>
        <div className='form-group row'>
          <label htmlFor='inputPassword' className='col-sm-6 col-form-label'>
            Reference Number
          </label>
          <div className='col-sm-6'>
            <input
              type='text'
              className='form-control mt-2 fs-5'
              value={refCodeValue}
              onChange={(e) => setRefCodeValue(e.target.value)}
            />
          </div>
        </div>

        <div className='text-end mt-4'>
          <button
            type='submit'
            className='btn btn-outline-success'
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            type='submit'
            className='btn btn-outline-secondary ms-5'
            onClick={closeCashModal}
          >
            Close
          </button>
        </div>
      </form>
    </>
  )
}

export default RefCodePaymentForm
