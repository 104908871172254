import React from 'react'

const SaleProductReport = ({ closeProductReportModal, products }) => {
  return (
    <div>
      <div className='mt-2 text-center h5 shadow-sm p-2 bg-white rounded'>
        Product Sale Details
      </div>
      <table className='table mt-2'>
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Description</th>
            <th className='text-center'>Qty</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <>
              <tr>
                <td>{product.refcode}</td>
                <td>{product.name}</td>
                <td className='text-center'>{product.quantity}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      <div className='text-end mt-2'>
        <button
          type='submit'
          class='btn btn-outline-secondary btn-sm'
          onClick={closeProductReportModal}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default SaleProductReport
