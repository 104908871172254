import React from 'react'

const InitialCashForm = ({
  handleSubmit,
  cashValue,
  setCashValue,
  closeCashModal,
}) => {
  return (
    <>
      <div className='text-center h5 shadow-sm p-2 bg-white rounded'>
        Initial Cash
      </div>
      <form className='mt-3 h4'>
        <div className='form-group row mx-1 '>
          <input
            type='text'
            className='form-control mt-1 fs-6'
            placeholder='RM'
            value={cashValue}
            onChange={(e) => setCashValue(e.target.value)}
          />
        </div>

        <div className='text-end mt-4'>
          <button
            type='submit'
            class='btn btn-outline-success btn-sm'
            disabled={!cashValue}
            onClick={handleSubmit}
          >
            <i class='bi bi-check-lg'></i> Submit
          </button>
          <button
            type='submit'
            class='btn btn-outline-danger ms-3 btn-sm'
            onClick={closeCashModal}
          >
            <i class='bi bi-x-lg'></i> Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default InitialCashForm
