import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SaleProductReport from '../../../components/Form/SaleProductReport'
import { Modal } from 'antd'
import { Checkbox } from 'antd'

const AdminReportList = () => {
  const [reports, setReports] = useState([])
  const [showProduct, setShowProduct] = useState(false)
  const [recordId, setRecordId] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [allTotal, setAllTotal] = useState(0)
  const [products, setProducts] = useState([])
  const [cashiers, setCashiers] = useState([])
  const [visibleProdcutModal, setVisibleProductModal] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [checked, setChecked] = useState([])
  const [totalByPaymentMethod, setTotalByPaymentMethod] = useState({})
  const [expandedRecords, setExpandedRecords] = useState([])

  const navigate = useNavigate()

  // Get Active Cashier
  const getActiveCashier = async () => {
    try {
      const { data } = await axios.get('/api/v1/report/get-active-cashier')
      if (data?.success) {
        setCashiers(data?.cashierList)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getActiveCashier()
  }, [])

  const handleRecordDetail = (id) => {
    if (expandedRecords.includes(id)) {
      setExpandedRecords(expandedRecords.filter((recordId) => recordId !== id))
    } else {
      setExpandedRecords([...expandedRecords, id])
    }
  }

  const getStartDate = moment(startDate).startOf('day').toDate()
  const getEndDate = moment(endDate).endOf('day').toDate()
  const searchOrderByDateResult = async () => {
    try {
      const { data } = await axios.post('/api/v1/report/search-order-date', {
        getStartDate,
        getEndDate,
      })
      setReports(data?.records)
      setAllTotal(data?.getAllTotal[0]?.totalorders || 0)
      setProducts(data?.products)
      setTotalByPaymentMethod(
        data?.totalByPaymentMethod.reduce((acc, curr) => {
          acc[curr._id] = curr.total
          return acc
        }, {})
      )
      setOpenSearch(true)
    } catch (error) {
      console.log(error)
      setAllTotal(0)
      setTotalByPaymentMethod({})
    }
  }

  const closeProductReportModal = (e) => {
    e.preventDefault()
    setVisibleProductModal(false)
  }

  // Filter by Cashier
  const handleCashierFilter = (value, id) => {
    let all = [...checked]
    if (value) {
      all.push(id)
    } else {
      all = all.filter((c) => c !== id)
    }
    setChecked(all)
    filterCashier(all)
  }

  // Filter Cashiers
  const filterCashier = async (updatedChecked) => {
    try {
      const { data } = await axios.post('/api/v1/report/filter-cashier', {
        getStartDate,
        getEndDate,
        checked: updatedChecked,
      })

      setReports(data?.records || [])
      setAllTotal(data?.getAllTotal[0]?.totalorders || 0)
      setTotalByPaymentMethod(
        data?.totalByPaymentMethod.reduce((acc, curr) => {
          acc[curr._id] = curr.total
          return acc
        }, {})
      )
      setProducts(data?.products || [])
    } catch (error) {
      console.error('Error filtering cashier:', error)
    }
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Sales Report'}>
      <div
        className='searchInventory shadow-sm p-2 bg-white rounded'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h5 style={{ margin: 0, color: '#49759e' }}>Sales Report</h5>
          <div className='col-auto'>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <div
          className='d-flex justify-content-between flex-wrap mt-10'
          style={{ marginTop: '10px' }}
        >
          <div className='d-flex mt-2'>
            <span className='me-2 fw-bold'>Start Date</span>
            <DatePicker
              selected={startDate}
              dateFormat={'yyyy-M-dd'}
              onChange={(date) => setStartDate(date)}
            />
            <span className='ms-3 me-2 fw-bold'>End Date</span>
            <DatePicker
              selected={endDate}
              dateFormat={'yyyy-M-dd'}
              onChange={(date) => setEndDate(date)}
            />
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={searchOrderByDateResult}
            >
              Submit
            </button>
          </div>
          <div className='fw-bold'>
            <button
              type='button'
              disabled={!openSearch}
              className={
                !openSearch
                  ? 'btn btn-outline-info btn-sm'
                  : 'btn btn-info btn-sm'
              }
              onClick={() => setVisibleProductModal(true)}
            >
              Product Sale Details
            </button>
          </div>
        </div>

        <div
          className='d-flex align-items-center justify-content-between fw-bold mb-1'
          style={{ marginTop: '10px' }}
        >
          <div>
            <span className='me-2'>Cashier:</span>
            <span>
              {cashiers.map((c) => (
                <span className='me-2' key={c._id}>
                  <Checkbox
                    disabled={!openSearch}
                    onChange={(e) =>
                      handleCashierFilter(e.target.checked, c._id)
                    }
                  >
                    {c.name}
                  </Checkbox>
                </span>
              ))}
            </span>
          </div>

          <div className='mx-2 fw-bold'>
            Total: RM {(Math.round(allTotal * 100) / 100).toFixed(2)} (Cash: RM{' '}
            {(totalByPaymentMethod?.Cash
              ? Math.round(totalByPaymentMethod.Cash * 100) / 100
              : 0
            ).toFixed(2)}
            , eWallet: RM{' '}
            {(totalByPaymentMethod?.eWallet
              ? Math.round(totalByPaymentMethod.eWallet * 100) / 100
              : 0
            ).toFixed(2)}
            )
          </div>
        </div>
      </div>
      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '140px' }}>
            <thead>
              <tr>
                <th scope='col'>Date</th>
                <th scope='col'>Cashier</th>
                <th scope='col'>Order ID</th>
                <th scope='col' className='text-end'>
                  Total (RM)
                </th>
                <th scope='col'>Payment</th>
                <th scope='col'>Reference No.</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reports.map((data) => (
                <>
                  <tr
                    className={
                      recordId === data._id && !showProduct ? 'viewDetails' : ''
                    }
                  >
                    <td>
                      {' '}
                      {moment(data.orderdate)
                        .subtract(8, 'hours')
                        .format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td>{data.cashier.name}</td>
                    <td>{data.orderid}</td>
                    <td className='text-end'>
                      {(Math.round(data.totalorders * 100) / 100).toFixed(2)}
                    </td>
                    <td>{data.paymentmethod}</td>
                    <td>{data.paymentrefno}</td>
                    <td className='text-end'>
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => handleRecordDetail(data._id)}
                      >
                        <i
                          className={
                            expandedRecords.includes(data._id)
                              ? 'bi bi-dash'
                              : 'bi bi-plus'
                          }
                        ></i>
                      </button>
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: expandedRecords.includes(data._id) ? '' : 'none',
                    }}
                  >
                    <td colSpan={7}>
                      {data.products.map((product, index) => (
                        <div className='mb-1' style={{ fontSize: '17px' }}>
                          {index + 1}.{' '}
                          <span className='ms-1 me-2'>{product.refcode}</span>{' '}
                          {product.name}{' '}
                          <span className='ms-2'>x {product.quantity}</span>
                          <span className='ms-3'>
                            RM{' '}
                            {(
                              Math.round(
                                product.quantity * product.price * 100
                              ) / 100
                            ).toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        onCancel={() => setVisibleProductModal(false)}
        footer={null}
        open={visibleProdcutModal}
        width={1000}
      >
        <SaleProductReport
          products={products}
          closeProductReportModal={closeProductReportModal}
        />
      </Modal>
    </Layout>
  )
}

export default AdminReportList
