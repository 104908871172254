import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import { useAuth } from '../../context/auth'
import { Modal } from 'antd'
import OrderForm from '../../components/Form/OrderForm'
import CashPaymentForm from '../../components/Form/CashPaymentForm'
import SearchProductForm from '../../components/Form/SearchProductForm'
import RefCodePaymentForm from '../../components/Form/RefCodePaymentForm'
import { useNavigate } from 'react-router-dom'
import InitialCashForm from '../../components/Form/InitialCashForm'
import { toast } from 'react-toastify'
import '../../styles/CashierStyle.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'

const CashierDashboard = () => {
  const navigate = useNavigate()
  const [auth, setAuth] = useAuth()
  const [cart, setCart] = useState([])
  const [totalOrder, setTotalOrder] = useState(0)
  const [totalQty, setTotalQty] = useState(0)
  const [updateQty, setUpdateQty] = useState(0)
  const [orderId, setOrderId] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleSearchModal, setVisibleSearchModal] = useState(false)
  const [visibleCashFormModal, setVisibleCashFormModal] = useState(false)
  const [changeAmount, setChangeAmount] = useState('')
  const [selectItem, setSelectItem] = useState(null)
  const [searchvalue, setSearchvalue] = useState({ keyword: '' })
  const [searchItemNameCode, setSearchItemNameCode] = useState('')
  const [searchProducts, setSearchProducts] = useState([])

  const [visibleWalletFormModal, setVisibleWalletFormModal] = useState(false)
  const [visiblePbbFormModal, setVisiblePbbFormModal] = useState(false)
  const [paymentrefno, setPaymentrefno] = useState('')

  const [openCounter, setOpenCounter] = useState(false)
  const [initialCash, setInitialCash] = useState('')
  const [visibleOpenCounterModal, setVisibleOpenCounterModal] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    getOrderId()
  }, [])

  useEffect(() => {
    getOpenCounter()
  }, [])

  useEffect(() => {
    const calculateTotals = () => {
      const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0)
      const totalAmount = cart.reduce(
        (sum, item) => sum + item.quantity * item.price,
        0
      )
      setTotalQty(totalQuantity)
      setTotalOrder(totalAmount)
    }

    calculateTotals()
  }, [cart])

  const getOrderId = async () => {
    try {
      const userId = auth?.user?._id
      const { data } = await axios.post('/api/v1/order/get-order-id', {
        userId,
      })
      const getOrderNumber = data.slice(3)
      const converOrderNumber = (parseInt(getOrderNumber) + 1)
        .toString()
        .padStart(5, '0')
      data !== '0'
        ? setOrderId(auth?.user?.code + converOrderNumber)
        : setOrderId(auth?.user?.code + '00001')
    } catch (error) {
      console.log(error)
    }
  }

  const getOpenCounter = async () => {
    try {
      const userId = auth?.user?._id
      const { data } = await axios.post('/api/v1/order/get-open-counter', {
        userId,
      })
      data !== null && data?.status !== 1
        ? setOpenCounter(true)
        : setOpenCounter(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.get(
        `/api/v1/order/order-product/${searchvalue.keyword}`
      )

      const isFound = cart.some((element) => {
        if (element.refcode === data.refcode) {
          return true
        }
        return false
      })

      if (isFound) {
        let index = cart.findIndex((item) => item.refcode === data.refcode)
        let newQty = cart[index].quantity + 1
        let updatedCart = {
          ...cart[index],
          quantity: newQty,
        }
        const newCarts = [...cart]
        newCarts[index] = updatedCart
        setCart(newCarts)
        setTotalOrder(totalOrder + cart[index].price)
        setTotalQty(totalQty + cart[index].quantity)
      } else {
        data.quantity = 1
        data._id = data._id
        setCart([...cart, data])
        setTotalOrder(totalOrder + data.price)
        setTotalQty(totalQty + data.quantity)
      }
      setSearchvalue({ keyword: '' })
    } catch (error) {
      console.log(error)
      setSearchvalue({ keyword: '' })
    }
  }

  const handlePayment = async (e, payment) => {
    e.preventDefault()
    try {
      const userId = auth?.user?._id
      const { data } = await axios.post('/api/v1/order/order-product/payment', {
        cart,
        userId,
        orderId,
        totalOrder,
        payment,
        paymentrefno,
        status: 'Complete',
      })
      setCart([])
      setVisibleCashFormModal(false)
      setVisibleWalletFormModal(false)
      setVisiblePbbFormModal(false)
      setChangeAmount('')
      setTotalOrder(0)
      setTotalQty(0)
      setPaymentrefno('')
      getOrderId()
      inputRef.current?.focus()
      toast.success('New Order Added', {
        autoClose: 1000,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const removeCartItem = (cartId) => {
    try {
      setCart((prevCart) => prevCart.filter((item) => item._id !== cartId))
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateQty = async (e) => {
    e.preventDefault()
    const updatedCart = cart.map((item) =>
      item._id === selectItem._id
        ? { ...item, quantity: parseInt(updateQty) }
        : item
    )
    setCart(updatedCart)
    setVisibleModal(false)
  }

  const closePaymentModal = (e) => {
    e.preventDefault()
    setVisibleCashFormModal(false)
    setVisibleWalletFormModal(false)
    setVisiblePbbFormModal(false)
    setVisibleOpenCounterModal(false)
    setInitialCash('')
    setChangeAmount('')
  }

  // Search Item Name and Code
  const handleSearchSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post('/api/v1/order/search-item-info', {
        searchItemNameCode,
      })
      setSearchProducts(data?.products)
    } catch (error) {
      console.log(error)
    }
    setSearchItemNameCode('')
  }

  const selectProduct = (e, product) => {
    e.preventDefault()
    const isFound = cart.some((item) => item.refcode === product.refcode)

    if (isFound) {
      const updatedCart = cart.map((item) =>
        item.refcode === product.refcode
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
      setCart(updatedCart)
    } else {
      setCart([...cart, { ...product, quantity: 1 }])
    }
    setSearchProducts([])
    setVisibleSearchModal(false)
  }

  // Add Initial Cash
  const handleAddInitialCash = async (e) => {
    e.preventDefault()
    try {
      const userId = auth?.user?._id
      const { data } = await axios.post('/api/v1/counter/initial-cash', {
        initialCash,
        userId,
      })
      setInitialCash('')
      setVisibleOpenCounterModal(false)
      setOpenCounter(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout title={'Dashboard'}>
      <div className='pt-2' style={{ backgroundColor: '#f8f8f8' }}>
        <div className='d-flex justify-content-between'>
          <div className='d-flex mb-2 w-50'>
            <form
              onSubmit={handleSearch}
              className='p-2'
              style={{ width: '60%' }}
            >
              <div>
                <div className='input-group'>
                  <input
                    ref={inputRef}
                    type='text'
                    autoFocus
                    className='form-control'
                    placeholder='Scan / Key in Item Code and Press Enter'
                    readOnly={!openCounter}
                    value={searchvalue.keyword}
                    onChange={(e) =>
                      setSearchvalue({
                        ...searchvalue,
                        keyword: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </div>
              </div>
            </form>
            <div className='p-2'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={!openCounter}
                onClick={() => {
                  setVisibleSearchModal(true)
                }}
              >
                <i className='bi bi-search'></i> Search Book Name
              </button>
            </div>
          </div>
          <div className='p-2 w-20 fs-5 fw-bold'>Order ID: {orderId}</div>
          <div className='p-2 w-10 fs-5 fw-bold'>Total Qty: {totalQty}</div>
          <div className='py-1 px-3 w-20 fs-4 fw-bold'>
            Total: RM {(Math.round(totalOrder * 100) / 100).toFixed(2)}
          </div>
        </div>
      </div>
      <div className='w-100 px-2'>
        <table className='table'>
          <thead className='saleContain'>
            <tr>
              <th style={{ width: '5%' }}>No.</th>
              <th scope='col'>Item Code</th>
              <th style={{ width: '40%' }}>Description</th>
              <th className='text-center'>Quantity</th>
              <th className='text-end'>Unit Price</th>
              <th className='text-end'>Total</th>
              <th></th>
            </tr>
          </thead>

          <tbody className='saleContain'>
            {cart?.map((c, index) => (
              <React.Fragment key={c._id}>
                <tr>
                  <td style={{ width: '5%' }}>{index + 1}</td>
                  <td>{c.refcode}</td>
                  <td style={{ width: '40%' }}>{c.name}</td>
                  <td className='text-center'>
                    {c.quantity}
                    <button
                      className='btn btn-outline-primary btn-sm border-0 ms-2'
                      onClick={() => {
                        setVisibleModal(true)
                        setUpdateQty(c.quantity)
                        setSelectItem(c)
                      }}
                    >
                      <i className='bi bi-pencil-square'></i>
                    </button>
                  </td>
                  <td className='text-end'>
                    {(Math.round(c.price * 100) / 100).toFixed(2)}
                  </td>
                  <td className='text-end'>
                    {(Math.round(c.price * c.quantity * 100) / 100).toFixed(2)}
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-outline-danger btn-sm'
                      onClick={() => removeCartItem(c._id)}
                    >
                      <i className='bi bi-trash3'></i>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className='fixed-bottom row align-items-center'
        style={{ backgroundColor: '#f8f8f8', height: '70px' }}
      >
        <div className='col ms-4'>
          <button
            className='btn btn-info'
            onClick={() => navigate('/cashier/transaction-records')}
          >
            Transaction Records
          </button>
          <button
            className={
              !openCounter
                ? 'btn btn-warning mx-3'
                : 'btn btn-outline-warning mx-3'
            }
            disabled={openCounter}
            onClick={() => setVisibleOpenCounterModal(true)}
          >
            Open Counter
          </button>
          <button
            className={
              !openCounter ? 'btn btn-outline-warning' : 'btn btn-warning'
            }
            disabled={!openCounter}
            onClick={() => navigate('/cashier/close-counter')}
          >
            Close Counter
          </button>
        </div>

        <div className='col mx-4' style={{ textAlign: 'right' }}>
          <button
            className={
              !cart || cart.length === 0
                ? 'btn btn-outline-success ms-4 btn-lg'
                : 'btn btn-success ms-4 btn-lg'
            }
            disabled={!cart || cart.length === 0}
            onClick={() => {
              setVisibleCashFormModal(true)
              setVisibleWalletFormModal(false)
              setVisiblePbbFormModal(false)
            }}
          >
            Cash
          </button>
          <button
            className={
              !cart || cart.length === 0
                ? 'btn btn-outline-success ms-4 btn-lg'
                : 'btn btn-success ms-4 btn-lg'
            }
            disabled={!cart || cart.length === 0}
            onClick={() => {
              setVisibleWalletFormModal(true)
              setVisibleCashFormModal(false)
              setVisiblePbbFormModal(false)
            }}
          >
            eWallet
          </button>
          {/* <button
            className={
              !cart || cart.length === 0
                ? 'btn btn-outline-success ms-4 btn-lg'
                : 'btn btn-success ms-4 btn-lg'
            }
            disabled={!cart || cart.length === 0}
            onClick={() => {
              setVisiblePbbFormModal(true)
              setVisibleCashFormModal(false)
              setVisibleWalletFormModal(false)
            }}
          >
            PBB
          </button> */}
        </div>
      </div>

      <Modal
        onCancel={() => setVisibleSearchModal(false)}
        footer={null}
        open={visibleSearchModal}
        width={1000}
      >
        <SearchProductForm
          value={searchItemNameCode}
          setValue={setSearchItemNameCode}
          handleSubmit={handleSearchSubmit}
          products={searchProducts}
          selectProduct={selectProduct}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleModal(false)}
        footer={null}
        open={visibleModal}
      >
        <OrderForm
          value={updateQty}
          setValue={setUpdateQty}
          handleSubmit={handleUpdateQty}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleCashFormModal(false)}
        footer={null}
        open={visibleCashFormModal}
      >
        <CashPaymentForm
          value={totalOrder}
          changeValue={changeAmount}
          setChangeValue={setChangeAmount}
          handleSubmit={(e) => handlePayment(e, 'Cash')}
          closeCashModal={closePaymentModal}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleWalletFormModal(false)}
        footer={null}
        open={visibleWalletFormModal}
      >
        <RefCodePaymentForm
          title='eWallet'
          value={totalOrder}
          refCodeValue={paymentrefno}
          setRefCodeValue={setPaymentrefno}
          handleSubmit={(e) => handlePayment(e, 'eWallet')}
          closeCashModal={closePaymentModal}
        />
      </Modal>
      <Modal
        onCancel={() => setVisiblePbbFormModal(false)}
        footer={null}
        open={visiblePbbFormModal}
      >
        <RefCodePaymentForm
          title='Public Bank'
          value={totalOrder}
          refCodeValue={paymentrefno}
          setRefCodeValue={setPaymentrefno}
          handleSubmit={(e) => handlePayment(e, 'Public Bank')}
          closeCashModal={closePaymentModal}
        />
      </Modal>
      <Modal
        onCancel={() => setVisibleOpenCounterModal(false)}
        footer={null}
        open={visibleOpenCounterModal}
      >
        <InitialCashForm
          handleSubmit={handleAddInitialCash}
          cashValue={initialCash}
          setCashValue={setInitialCash}
          closeCashModal={closePaymentModal}
        />
      </Modal>
    </Layout>
  )
}

export default CashierDashboard
